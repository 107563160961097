import React from 'react'
import { Box, Button, Flex, Img, Link } from '@chakra-ui/react'

const Header: React.FC = () => {
    return (
        <Box zIndex="2" position="relative" marginY={{ base: '27px' }}>
            <Link
                position="absolute"
                top={{ base: '8px' }}
                left={{ base: '14px', lg: '70px' }}
                href="https://www.sielbleu.org/"
                target="_blank"
            >
                <Img
                    width={{ base: '125px', md: '174px' }}
                    objectFit="cover"
                    src={'/images/sielbleu/logo_siel_bleu.png'}
                    alt="siel bleu logo"
                />
            </Link>
            <Flex justify="end" margin="15px">
                <Button>
                    <a rel="noopener noreferrer" href="https://www.sielbleu.org/soutenir" target="_blank">
                        Faire un don
                    </a>
                </Button>
            </Flex>
        </Box>
    )
}

export default Header
