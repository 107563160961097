import React, { ReactElement } from 'react'
import { Box, useBreakpointValue } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

type CarouselItem = {
    text: string
    link?: string
    title?: string
    status?: string
    img?: {
        src: string | undefined
        alt: string
    }
}

type CarouselProps = {
    datas: CarouselItem[]
    centerSlide?: number
    renderSlide: (data: CarouselItem, index: number) => ReactElement
}

const CustomArrow = (onClickHandler: React.MouseEventHandler<HTMLDivElement>, direction: string) => (
    <Box
        className="custom-arrow"
        right={direction === 'next' ? '0px' : undefined}
        left={direction === 'prev' ? '0px' : undefined}
        onClick={onClickHandler}
    >
        {direction === 'prev' && <ChevronLeftIcon boxSize={10} color="brand.heavy" />}
        {direction === 'next' && <ChevronRightIcon boxSize={10} color="brand.heavy" />}
    </Box>
)

const CustomDots = (clickHandler: React.MouseEventHandler<HTMLDivElement> | undefined, isSelected: boolean) => {
    return (
        <Box
            className="custom-dot"
            onClick={clickHandler}
            backgroundColor={isSelected ? 'brand.heavy' : 'rgba(3,128,207, 0.3)'}
        ></Box>
    )
}

const PetitsPasCarousel = ({ datas, renderSlide, centerSlide }: CarouselProps) => {
    const hasMoreThanOneElement = datas.length > 1
    const displayCount = useBreakpointValue({ base: 2, lg: 3 }) || 3

    return (
        <Box className="carousel-container">
            <Carousel
                centerMode={centerSlide !== undefined}
                centerSlidePercentage={100 / displayCount}
                infiniteLoop={centerSlide === undefined}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                showIndicators={hasMoreThanOneElement}
                swipeable={false}
                autoPlay={centerSlide !== undefined}
                renderArrowPrev={hasMoreThanOneElement ? (e) => CustomArrow(e, 'prev') : undefined}
                renderArrowNext={hasMoreThanOneElement ? (e) => CustomArrow(e, 'next') : undefined}
                renderIndicator={hasMoreThanOneElement ? CustomDots : undefined}
            >
                {datas.map((data, index) => renderSlide(data, index))}
            </Carousel>
        </Box>
    )
}

export default PetitsPasCarousel
