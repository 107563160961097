
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/_sites/petits-pas",
      function () {
        return require("private-next-pages/_sites/petits-pas/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/_sites/petits-pas"])
      });
    }
  