import React, { useEffect } from 'react'
import Header from '~components/sielbleu/petits-pas/header'
import Footer from '~components/sielbleu/layout/footer/index'
import { Box, Divider, Link, useStyleConfig } from '@chakra-ui/react'
import Logo from '~components/sielbleu/layout/footer/logo'
import Social from '~components/sielbleu/petits-pas/social'
import Links from '~components/sielbleu/layout/footer/links'

declare global {
    interface Window {
        hbspt: { forms: any }
        _axcb: any[]
        axeptioSettings: any
        dataLayer: any[]
    }
}

const Layout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const footerStyles = useStyleConfig('Footer', { variant: 'landing' })

    const [hubspotEnabled, setHubspotEnabled] = React.useState(false)
    const [googleAnalyticsEnabled, setGoogleAnalyticsEnabled] = React.useState(false)

    useEffect(() => {
        window.axeptioSettings = {
            clientId: '66618d7778f6c06a5801cf96',
            cookiesVersion: 'petitspas-fr-EU',
            googleConsentMode: {
                default: {
                    analytics_storage: 'denied',
                    ad_storage: 'denied',
                    ad_user_data: 'denied',
                    ad_personalization: 'denied',
                    wait_for_update: 500,
                },
            },
        }

        const script = document.createElement('script')
        script.async = true
        script.src = '//static.axept.io/sdk.js'
        document.body.appendChild(script)

        void 0 === window._axcb && (window._axcb = [])
        window._axcb.push((axeptio: any) => {
            axeptio.on('cookies:complete', (choices: any) => {
                setGoogleAnalyticsEnabled(choices.google_analytics ?? false)
                setHubspotEnabled(choices.hubspot ?? false)
            })
        })
    }, [])

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID && hubspotEnabled) {
            const script = document.createElement('script')
            script.async = true
            script.src = '//js-eu1.hs-scripts.com/' + process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID + '.js'
            document.body.appendChild(script)

            return () => document.body.removeChild(script)
        }

        return () => {}
    }, [hubspotEnabled])

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_PETITS_PAS_GA4_ID && googleAnalyticsEnabled) {
            const id = process.env.NEXT_PUBLIC_PETITS_PAS_GA4_ID
            const script = document.createElement('script')
            script.async = true
            script.src = '//www.googletagmanager.com/gtag/js?id=' + id
            document.body.appendChild(script)

            window.dataLayer = window.dataLayer || []

            // @ts-ignore
            function gtag() {
                window.dataLayer.push(arguments)
            }

            // @ts-ignore
            gtag('js', new Date())
            // @ts-ignore
            gtag('config', id)

            return () => document.body.removeChild(script)
        }

        return () => {}
    }, [googleAnalyticsEnabled])

    return (
        <>
            <Header />
            <Box>{children}</Box>
            <Box __css={footerStyles}>
                <Footer>
                    <Box className="footer-container">
                        <Box className="follow-us">
                            <Logo />
                            <Social />
                        </Box>
                        <Divider />
                        <Links />
                    </Box>
                </Footer>
            </Box>
        </>
    )
}

export default Layout
